export const partner = [
    {
        img: 'abacus_new.png',
        name: 'Abacus Business Software',
        link: 'https://www.abacus.ch'
    },
    {
        img: 'easylearn.png',
        name: 'easylearn',
        link: 'https://www.easylearn.ch/de/'
    },
    {
        img: 'ibm.png',
        name: 'IBM',
        link: 'https://www.ibm.com/ch-de/'
    }
]