export const customer = [
    {
        img: 'amag.png',
        name: 'AMAG',
        link: 'https://www.amag.ch',
        group: 'Handel'
    },
    {
        img: 'APG.png',
        name: 'APG|SGA',
        link: 'https://www.apgsga.ch/',
        group: 'Handel'
    },
    {
        img: 'akb.png',
        name: 'Aargauische Kantonalbank',
        link: 'https://www.akb.ch/',
        group: 'Finanzen'
    },
    {
        img: 'cler.png',
        name: 'Bank CLER',
        link: 'https://www.cler.ch',
        group: 'Finanzen'
    },
    {
        img: 'BaslerKantonalbank.png',
        name: 'Basler Kantonalbank',
        link: 'https://www.bkb.ch/',
        group: 'Finanzen'
    },
    {
        img: 'bildungsdirektion.png',
        name: 'Bildungsdirektion Kanton Zürich',
        link: 'https://bi.zh.ch/internet/bildungsdirektion/de/home.html/',
        group: 'Verwaltung'
    },
    {
        img: 'lgt.png',
        name: 'LGT Group',
        link: 'https://www.lgt.com',
        group: 'Finanzen'
    },
    {
        img: 'livit.png',
        name: 'Livit - Real Estate Management',
        link: 'https://www.livit.ch',
        group: 'Dienstleistung'
    },
    {
        img: 'raiffeisen.png',
        name: 'Raiffeisen',
        link: 'https://www.raiffeisen.ch',
        group: 'Finanzen'
    },
    {
        img: 'ringier.png',
        name: 'Ringier Print AG',
        link: 'https://www.ringier.ch',
        group: 'Dienstleistung'
    },
    {
        img: 'sgkb.png',
        name: 'St.Galler Kantonalbank',
        link: 'https://www.sgkb.ch/',
        group: 'Finanzen'
    },
    {
        img: 'swica.png',
        name: 'SWICA',
        link: 'https://www.swica.ch',
        group: 'Versicherung'
    },
    {
        img: 'zkb.png',
        name: 'Zuger Kantonalbank',
        link: 'https://www.zugerkb.ch',
        group: 'Finanzen'
    },
    {
        img: 'zg.png',
        name: 'Kanton Zug',
        link: 'https://www.zg.ch',
        group: 'Verwaltung'
    },
    {
        img: 'pistor.png',
        name: 'Pistor AG',
        link: 'https://www.pistor.ch',
        group: 'Handel'
    },
    {
        img: 'bekb.png',
        name: 'Berner Kantonalbank',
        link: 'https://www.bekb.ch',
        group: 'Finanzen'
    },
    {
        img: 'chur.png',
        name: 'Stadt Chur',
        link: 'https://www.chur.ch/',
        group: 'Verwaltung'
    },
    {
        img: 'Baar.png',
        name: 'Baar',
        link: 'https://www.baar.ch/',
        group: 'Verwaltung'
    },
    {
        img: 'Cham.png',
        name: 'Cham',
        link: 'http://www.cham.ch/de/',
        group: 'Verwaltung'
    },
    {
        img: 'Hünenberg.png',
        name: 'Hünenberg',
        link: 'https://www.zg.ch/behoerden/gemeinden/hunenberg/de',
        group: 'Verwaltung'
    },
    {
        img: 'Menzingen.png',
        name: 'Menzingen',
        link: 'https://www.zg.ch/behoerden/gemeinden/menzingen/de',
        group: 'Verwaltung'
    },
    {
        img: 'Neuheim.png',
        name: 'Neuheim',
        link: 'https://www.neuheim.ch/',
        group: 'Verwaltung'
    },
    {
        img: 'Oberägeri.png',
        name: 'Oberägeri',
        link: 'https://www.oberaegeri.ch/',
        group: 'Verwaltung'
    },
    {
        img: 'Risch.png',
        name: 'Risch',
        link: 'https://www.zg.ch/behoerden/gemeinden/risch-rotkreuz',
        group: 'Verwaltung'
    },
    {
        img: 'Steinhausen.png',
        name: 'Steinhausen',
        link: 'https://www.zg.ch/behoerden/gemeinden/steinhausen',
        group: 'Verwaltung'
    },
    {
        img: 'Unterägeri.png',
        name: 'Unterägeri',
        link: 'https://www.unteraegeri.ch/',
        group: 'Verwaltung'
    },
    {
        img: 'Walchwil.png',
        name: 'Walchwil',
        link: 'http://www.walchwil.ch/xml_1/internet/de/intro.cfm',
        group: 'Verwaltung'
    },
    {
        img: 'Zug.png',
        name: 'Zug',
        link: 'https://www.stadtzug.ch/',
        group: 'Verwaltung'
    },
    {
        img: 'KantonGraubünden.png',
        name: 'Kanton Graubünden',
        link: 'https://www.gr.ch/DE/Seiten/welcome.aspx',
        group: 'Verwaltung'
    },
    {
        img: 'GDELS.png',
        name: 'GDELS',
        link: 'https://www.gdels.com/',
        group: 'Produktion'
    },
    {
        img: 'UMB.png',
        name: 'UMB',
        link: 'https://www.umb.ch/',
        group: 'Dienstleistung'
    },
    {
        img: 'ADNovum.png',
        name: 'ADNovum',
        link: 'https://www.adnovum.ch/de/',
        group: 'Dienstleistung'
    },
    {
        img: 'Sertronics.png',
        name: 'Sertronics',
        link: 'https://www.sertronics.ch/',
        group: 'Dienstleistung'
    },
    {
        img: 'Biopartner.jpg',
        name: 'Biopartner AG',
        link: 'https://www.biopartner.ch/',
        group: 'Handel'
    },
    {
        img: 'Haefely.png',
        name: 'Haefely AG',
        link: 'https://www.pfiffner-group.com/about-pfiffner-group/haefely',
        group: 'Produktion'
    },
    {
        img: 'SDE.png',
        name: 'SDE Services',
        link: 'https://www.sdeservices.ch/',
        group: 'Dienstleistung'
    },
    {
        img: 'Sonepar.jpg',
        name: 'Sonepar Suisse AG',
        link: 'https://www.sonepar.ch/',
        group: 'Handel'
    },
    {
        img: 'thomann.png',
        name: 'Thomann Nutzfahrzeuge AG',
        link: 'https://www.thomannag.com/',
        group: 'Produktion'
    },
    {
        img: 'gallo.png',
        name: 'Gallo AG',
        link: 'https://www.gallo.ch/de',
        group: 'Dienstleistung'
    },
    {
        img: 'baden.svg',
        name: 'Stadt Baden',
        link: 'https://www.baden.ch/',
        group: 'Verwaltung'
    },
    {
        img: 'thurgau.svg',
        name: 'Kanton Thurgau',
        link: 'https://www.tg.ch/',
        group: 'Verwaltung'
    },
    {
        img: 'phtg.svg',
        name: 'Pädagogische Hochschule Thurgau',
        link: 'https://www.phtg.ch/',
        group: 'Verwaltung'
    },
    {
        img: 'bechtle.png',
        name: 'Bechtle AG',
        link: 'https://www.bechtle.com/ch',
        group: 'Dienstleistung'
    },
    {
        img: 'huber-suhner.png',
        name: 'HUBER+SUHNER',
        link: 'https://www.hubersuhner.com/de',
        group: 'Produktion'
    },
    {
        img: 'WDFH.jpg',
        name: 'Wandfluh AG',
        link: 'https://www.wandfluh.com/de/',
        group: 'Produktion'
    },
    {
        img: 'HGK.png',
        name: 'HGK Davos',
        link: 'https://www.hochgebirgsklinik.ch/',
        group: 'Dienstleistung'
    },
    {
        img: 'LDQ.jpg',
        name: 'Landqart AG',
        link: 'https://www.landqart.com/',
        group: 'Produktion'
    },
    {
        img: 'JMG.jpg',
        name: 'Jakob Müller Group',
        link: 'https://www.mueller-frick.com/de/',
        group: 'Produktion'
    },
]