export const certificat = [
    {
        img: 'GoldPartnerTime.png',
        link: 'https://www.lepa.ch/static/Gold_2024_LEHMANN+PARTNER_Informatik_AG-5b3abfd9e2716450a3e45d35849a7a82.pdf'
    },
    {
        img: 'GoldPartnerSpesen.png',
        link: 'https://www.lepa.ch/static/Gold_2024_LEHMANN+PARTNER_Informatik_AG-5b3abfd9e2716450a3e45d35849a7a82.pdf'
    }


]